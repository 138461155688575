import Paragraph from '@components/paragraph';
import ViewContainer from '@components/viewContainer';
import React from 'react';

const Kenshikai = (): React.ReactElement => {
    return (
        <ViewContainer>
            <h1 className="title is-4">Okinawa Goju Ryu Karate</h1>
            <Paragraph>
                Karate has a long history as martial art, with roots spanning China and the Okinawa
                Islands. Karate developed into its present form in Okinawa at the end of the 19th
                century, from where it was taken to Japan&apos;s main islands in the early 20th
                century by Okinawa champions.
            </Paragraph>
            <Paragraph>
                Kanryo Higaonna (1853-1915) is considered the father of Goju ryu karate. His style
                was most significantly influenced by Fuzhou, China, where he studied for years under
                the guidance of Chinese masters, most importantly Ryu Ryu Ko.
            </Paragraph>
            <Paragraph>
                After returning from his 14-year trips to China to Okinawa, he began teaching
                martial art skills in Nahan Nishihara. The style he taught was named Naha-te. One of
                his most important students was Chojun Miyagi (1888-1953). Miyagi is considered the
                founder of goju ryu.
            </Paragraph>
            <Paragraph>
                Goju Ryu developed into one of karate&apos;s greatest and most famous styles
                worldwide. The name goju ryu means hard-soft school. Go also means direct technique,
                exhalation and external force. Ju, or soft, also conceptually includes circular
                techniques, inhalation and internal power. These basic principles are based on the
                practice of goju ryu karate.
            </Paragraph>
            <Paragraph>
                Okinawa&apos;s goju ryu training emphasises more short distance combat such as
                grabbling compared to other karate styles. An important part of the training is to
                try to develop force that can perform effective techniques at close range. The
                production of power is based on physical technique, mental attitude and their
                binding breathing.
            </Paragraph>
            <Paragraph>
                Karate&apos;s training consists of three main areas: kata (movement sequence), kihon
                (basic technical training) and kumite (pair training in various forms).
            </Paragraph>
        </ViewContainer>
    );
};

export default Kenshikai;
